import { UIStreamCategories } from '@ikon-web/event-shared';
import { UiContainerModel, UiContainerOrElement } from '@ikon-web/ikon-client';
import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

const containerAdapter = createEntityAdapter({
  selectId: (message: UiContainerModel) => message.containerId,
});

type RootContainer = { id: string; containers: { id: string; sortingId: number }[]; timestamp: number };

const extraState: {
  chatContainers: RootContainer[];
  chatContainerUpdatesCount: number;
  debugOverlayContainers: RootContainer[];
  headerContainers: RootContainer[];
  secondScreenContainers: RootContainer[];
} = { chatContainers: [], chatContainerUpdatesCount: 0, debugOverlayContainers: [], headerContainers: [], secondScreenContainers: [] };

const removeContainerById = (state: RootState['container'], id: string) => {
  const removeContainer = (containerId: string) => {
    containerAdapter.removeOne(state, containerId);

    let index = state.chatContainers.findIndex((c) => c.id === containerId);
    if (index >= 0) state.chatContainers.splice(index, 1);

    index = state.headerContainers.findIndex((c) => c.id === containerId);
    if (index >= 0) state.headerContainers.splice(index, 1);

    index = state.debugOverlayContainers.findIndex((c) => c.id === containerId);
    if (index >= 0) state.debugOverlayContainers.splice(index, 1);
  };

  const processElements = (elements: UiContainerOrElement[]) => {
    for (const element of elements) {
      if ('elements' in element) processElements(element.elements);
      if (element.type === 'container') removeContainer(element.containerId);
    }
  };

  const container = state.entities[id];
  if (container) {
    removeContainer(id);
    processElements(container.elements);
  }
};

const setRootContainer = (groupContainers: RootContainer[], container: UiContainerModel) => {
  if (container.isUpdate) return;

  const group = groupContainers.find((c) => c.id === container.groupId);
  if (group) {
    // New container added to existing group
    if (group.containers.some((c) => c.id === container.containerId)) {
      console.warn('[ContainerSlice] Container already exists in group and is not an update', container.containerId);
      return;
    }

    group.containers.push({ id: container.containerId, sortingId: container.sortingId });
    group.containers.sort((a, b) => a.sortingId - b.sortingId);
  } else {
    // New group started
    groupContainers.push({
      id: container.groupId,
      containers: [{ id: container.containerId, sortingId: container.sortingId }],
      timestamp: new Date(container.createdAt).valueOf(),
    });
    groupContainers.sort((a, b) => a.timestamp - b.timestamp);
  }
};

const containerSlice = createSlice({
  name: 'container',
  initialState: containerAdapter.getInitialState(extraState),
  reducers: {
    receiveContainer: (state, action: PayloadAction<UiContainerModel>) => {
      const processElements = (elements: UiContainerOrElement[]) => {
        for (const element of elements) {
          if (element.type === 'container') containerAdapter.setOne(state, element);
          if ('elements' in element) processElements(element.elements);
        }
      };

      if (action.payload.type !== 'container') return;

      // Updates where container is no longer in memory can be ignored
      if (action.payload.isUpdate && !state.ids.includes(action.payload.containerId)) return;

      // Set container entity into store
      containerAdapter.setOne(state, action.payload);
      processElements(action.payload.elements);

      // Handle root containers
      switch (action.payload.category) {
        case UIStreamCategories.Chat:
          state.chatContainerUpdatesCount++;
          setRootContainer(state.chatContainers, action.payload);
          break;
        case UIStreamCategories.DebugOverlay:
          setRootContainer(state.debugOverlayContainers, action.payload);
          break;
        case UIStreamCategories.Header:
          setRootContainer(state.headerContainers, action.payload);
          break;
        case UIStreamCategories.SecondScreen:
          setRootContainer(state.secondScreenContainers, action.payload);
          break;
      }
    },
    removeContainer: (state, action: PayloadAction<string>) => {
      removeContainerById(state, action.payload);
    },
    receiveText: (state, action: PayloadAction<{ containerId: string; elementId: number; text: string }>) => {
      const container = state.entities[action.payload.containerId];
      if (!container) return;

      let element: UiContainerOrElement | undefined;
      const findElement = (elements: UiContainerOrElement[]) => {
        for (const e of elements) {
          if ('elements' in e) findElement(e.elements);
          if (e['elementId'] === action.payload.elementId) {
            element = e;
            return;
          }
        }
      };
      findElement(container.elements);

      if (!element) return;
      (element as any).element.text += action.payload.text;
    },
    setContainerStable: (state, action: PayloadAction<string>) => {
      const container = state.entities[action.payload];
      if (!container) return;

      container.isStable = true;
    },
    clearChatContainers: (state) => {
      const chatContainers = [...state.chatContainers];
      for (const chatContainer of chatContainers) {
        for (const container of chatContainer.containers) {
          removeContainerById(state, container.id);
        }
      }
      state.chatContainers = [];
    },
    reset: () => containerAdapter.getInitialState(extraState),
  },
});

export const containerReducer = containerSlice.reducer;

export const { receiveContainer, removeContainer, receiveText, setContainerStable, clearChatContainers, reset: resetContainer } = containerSlice.actions;

export const { selectById: selectContainerById } = containerAdapter.getSelectors<RootState>((state) => state.container);

export const selectChatContainerIds = createSelector(
  (state: RootState) => state.container.chatContainers,
  // (state: RootState) => state.container.entities,
  (chatContainers) => {
    return chatContainers.flatMap((c) => {
      return c.containers.map((cc) => cc.id);
      // const outputContainers: string[] = [];
      //
      // let previousStable = false;
      // for (const { id: containerId } of c.containers) {
      //   const container = entities[containerId];
      //   if (!container) continue;
      //
      //   let hidden = false;
      //
      //   if (container.visibility === UIVisibilityType.VisibleAfterEarlierStable) {
      //     if (!previousStable) hidden = true;
      //   }
      //
      //   previousStable = container.isStable;
      //   if (!hidden) outputContainers.push(containerId);
      // }
      //
      // return outputContainers;
    });
  },
);

export const selectChatContainerUpdatesCount = createSelector(
  (state: RootState) => state.container,
  (containerState) => containerState.chatContainerUpdatesCount,
);

export const selectDebugOverlayContainerIds = createSelector(
  (state: RootState) => state.container.debugOverlayContainers,
  (debugOverlayContainers) => {
    return debugOverlayContainers.flatMap((c) => c.containers.map((cc) => cc.id));
  },
);

export const selectHeaderContainerIds = createSelector(
  (state: RootState) => state.container.headerContainers,
  (headerContainers) => {
    return headerContainers.flatMap((c) => c.containers.map((cc) => cc.id));
  },
);

export const selectSecondScreenContainerIds = createSelector(
  (state: RootState) => state.container.secondScreenContainers,
  (secondScreenContainers) => {
    return secondScreenContainers.flatMap((c) => c.containers.map((cc) => cc.id));
  },
);
